import styled from "styled-components";

export const Heading = styled.h2`
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  text-align: center;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;
