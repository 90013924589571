import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import EllipsizedTextView from "./EllipsizedText.view";

const EllipsizedText = ({
  text,
  showSeeMore = false,
  defaultHeight = 180,
  textAlign = "justify",
  setTextAsHtml = true,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const contentRef = useRef(null);
  const [contentExceedsHeight, setContentExceedsHeight] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      setContentExceedsHeight(contentRef.current.scrollHeight > defaultHeight);
    }
  }, [text, defaultHeight]);

  const toggleTextVisibility = () => {
    setShowFullText(!showFullText);
  };

  return (
    <EllipsizedTextView
      contentRef={contentRef}
      showFullText={showFullText}
      defaultHeight={defaultHeight}
      text={text}
      showSeeMore={showSeeMore}
      contentExceedsHeight={contentExceedsHeight}
      toggleTextVisibility={toggleTextVisibility}
      textAlign={textAlign}
      setTextAsHtml={setTextAsHtml}
    />
  );
};

EllipsizedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EllipsizedText;
