import React, { useMemo } from "react";
import useAuthActions from "@/store/actions/authAction";
import useMobileBreakPoint from "./useMobileBreakPoint";

function UseComponentVisibility({ conditions = {}, children }) {
  if (!conditions?.data?.length) {
    return <>{children}</>;
  }

  const { auth } = useAuthActions();
  const isMobile = useMobileBreakPoint();

  const conditionSlugs = useMemo(
    () => conditions.data.map((item) => item.slug),
    [conditions]
  );

  const conditionMap = useMemo(() => {
    const loggedIn = Boolean(auth?.token);
    return {
      mobile: isMobile,
      desktop: !isMobile,
      loggedIn: loggedIn,
      loggedOut: !loggedIn,
      mobile_loggedIn: isMobile && loggedIn,
      mobile_loggedOut: isMobile && !loggedIn,
      desktop_loggedIn: !isMobile && loggedIn,
      desktop_loggedOut: !isMobile && !loggedIn,
    };
  }, [isMobile, auth]);

  const isVisible = useMemo(() => {
    return conditionSlugs.some((condition) => conditionMap[condition]);
  }, [conditionMap, conditionSlugs]);

  return isVisible ? <>{children}</> : null;
}

export default UseComponentVisibility;
