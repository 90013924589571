import styled from "styled-components";
import { IoIosArrowDropupCircle } from "react-icons/io";

export const AccordionItem = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius.xs};
  ${(p) => p.isOpen && `box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;`}
  margin-bottom: 16px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
  }
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.borderGray};
`;

export const IconDiv = styled(IoIosArrowDropupCircle)`
  --icon-size: 27px;
  height: var(--icon-size);
  width: var(--icon-size);
  padding: 6px;
  cursor: pointer;
  text-align: center;
  background-color: ${(p) => p.theme.colors.themeYellow};
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  transform: rotate(${(p) => p.rotation});
`;

export const AccordionHeader = styled.div`
  align-items: center;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  height: fit-content;
  max-height: ${(p) => p.maxHeight};
  padding: ${(p) => (p.expanded ? "16px" : "0 16px")};
  padding-top: 0px;
  transition: all 0.3s ease-in-out;
`;

export const AccordionHeading = styled.h3`
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  width: calc(100% - 32px);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
