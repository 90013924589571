import React from "react";
import FaqsListView from "./FaqsList.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

function FaqsList({
  listData,
  id,
  heading = "Frequently Asked Questions",
  showOn,
}) {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="faq">
        <FaqsListView faqs={listData} id={id} heading={heading} />
      </section>
    </UseComponentVisibility>
  );
}

export default FaqsList;
