import styled from "styled-components";

export const EllipsizedContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: ${(p) =>
    p.showFullText ? "fit-content" : `${p.defaultHeight}px`};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export const ReadMoreToggle = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.colors.themeBlue};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  text-decoration: underline;
`;
