import { styled } from "styled-components";
import { YellowButton } from "@/outscal-commons-frontend/Styled/Buttons";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.alignment};
  justify-content: center;
  gap: ${(p) => `${p.gridGap}px`};
  @media (min-width: ${(p) => p.theme.breakpoints.sm}px) {
    border-radius: ${(p) => p.theme.borderRadius.sm};
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: ${(props) => props.gap || "12px"};
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  @media (max-width: 900px) {
    grid-template-columns: repeat(
      ${(props) => Math.floor(props.columns / 2)},
      1fr
    );
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(${(p) => p.minColCount}, 1fr);
  }
`;

export const CTA = styled(YellowButton)`
  padding: 8px 20px;
  background-color: ${(p) => p.theme.colors.themeYellow};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;
