import React from "react";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { EllipsizedContainer, ReadMoreToggle } from "./EllipsizedText.styles";

const EllipsizedTextView = ({
  contentRef,
  showFullText,
  defaultHeight,
  text,
  showSeeMore,
  contentExceedsHeight,
  toggleTextVisibility,
  textAlign,
  setTextAsHtml,
}) => {
  return (
    <>
      {" "}
      <EllipsizedContainer
        ref={contentRef}
        showFullText={showFullText}
        defaultHeight={defaultHeight}
      >
        {setTextAsHtml ? (
          <Text
            fontSize="sm"
            color="textDarkerGrey"
            style={{ lineHeight: "20px", textAlign: textAlign }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <Text
            fontSize="sm"
            color="textDarkerGrey"
            style={{ lineHeight: "20px", textAlign: textAlign }}
          >
            {text}
          </Text>
        )}
      </EllipsizedContainer>
      {showSeeMore && contentExceedsHeight && (
        <ReadMoreToggle onClick={toggleTextVisibility}>
          {showFullText ? " Read Less" : " Read More"}
        </ReadMoreToggle>
      )}
    </>
  );
};

export default EllipsizedTextView;
