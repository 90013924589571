import React from "react";
import { Heading } from "./FaqsList.styles";
import { ColDiv, Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import AccordionController from "@/mvcComponents/Commons/Accordion/Accordion.controller";

function FaqsListView({ faqs, id, heading }) {
  return faqs?.length ? (
    <Div padding="10px">
      <Heading>{heading}</Heading>
      <ColDiv gap="15px" id={id}>
        {faqs.map((faq, i) => (
          <AccordionController
            key={faq._id}
            defaultOpen={i === 0}
            title={faq.question}
            description={faq.answer}
            setTextAsHtml={false}
          />
        ))}
      </ColDiv>
    </Div>
  ) : null;
}

export default FaqsListView;
