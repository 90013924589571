import React from "react";
import TextSectionView from "./TextSection.view";
import { alignmentMapping } from "./TextSection.model";

const TextSection = ({
  heading = "",
  bodyText = "",
  alignment = "center",
  action = {},
  gridGap = 16,
  ellipsizeText = false,
  setTextAsHtml=true,
  defaultHeight=60
}) => {
  const {
    redirectionLink = "",
    actionText = "",
    actionFunction = () => {},
  } = action || {};
  return (
    <TextSectionView
      alignment={alignmentMapping[alignment]}
      heading={heading}
      bodyText={bodyText}
      gridGap={gridGap}
      redirectionLink={redirectionLink}
      actionFunction={actionFunction}
      actionText={actionText}
      ellipsizeText={ellipsizeText}
      setTextAsHtml={setTextAsHtml}
      defaultHeight={defaultHeight}
    />
  );
};

export default TextSection;
