import React from "react";
import {
  AccordionContent,
  AccordionHeader,
  AccordionHeading,
  AccordionItem,
  IconDiv,
} from "./Accordion.styles";
import { RowDiv, Theme } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import TextSection from "../TextSection/TextSection.controller";

function AccordionView({
  toggleAccordion,
  title,
  isOpen,
  description,
  setTextAsHtml,
  alignment,
  defaultHeight
}) {
  return (
    <AccordionItem isOpen={isOpen}>
      <AccordionHeader
        onClick={() => toggleAccordion()}
        data-analytics={[UIElements.CARD, `faq`]}
      >
        <RowDiv justifyContent="space-between" alignItems="center">
          <AccordionHeading>{title}</AccordionHeading>
          <IconDiv
            color={Theme.colors.white}
            rotation={isOpen ? `0deg` : "180deg"}
          ></IconDiv>
        </RowDiv>
      </AccordionHeader>
      <AccordionContent maxHeight={isOpen ? `1000px` : "0px"} expanded={isOpen}>
        <TextSection
          bodyText={description}
          alignment={alignment}
          defaultHeight={defaultHeight}
          ellipsizeText={true}
          setTextAsHtml={setTextAsHtml}
        />
      </AccordionContent>
    </AccordionItem>
  );
}

export default AccordionView;
